.about {
  position: relative;
  color: $black;

  &__title {
    position: absolute;
    top: 0;
    left: 20px;
  }

  &__subtitle {
    max-width: 1116px;
    margin-left: auto;
    margin-bottom: 70px;
  }
  &__list {
    display: flex;
    max-width: 833px;
    gap: 20px;
    margin-left: auto;
  }
  &__item {
    max-width: 264px;
    display: flex;
    flex-direction: column;
    &-out {
      @include text_num;
      padding-bottom: 30px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $black;
        opacity: .7;
      }
    }
    &-text {
      margin-top: 15px;
      @include text_main;
      opacity: .9;
    }
  }
}


@media screen and (max-width: 992px) {
  .about {
    &__subtitle {
      max-width: 383px;
      margin-bottom: 35px;
    }

    &__item {
      &-out {
        padding-bottom: 20px;
      }
      &-text {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .about {
    &__title {
      left: 10px;
    }

    &__subtitle {
      max-width: 222px;
      margin-bottom: 30px;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      border-bottom: 1px solid #1d1d1db3;

      &-out {
        max-width: 106px;
        width: 100%;
        padding-bottom: 15px;

        &::after {
          display: none;
        }
      }

      &-text {
        max-width: 206px;
        margin-top: 0;
        margin-right: 16px;
      }
    }
  }
}