.header {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 0;

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__logo {
    width: 226px;
    margin-top: 5px;
    display: block;

    img {
      width: 100%;
      display: block;
    }
  }
}

.menu {
  max-width: 550px;
  width: 100%;
  display: flex;
  align-items: center;

}

.nav {
  max-width: 263px;
  width: 100%;

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    li {
      width: 65px;
      display: flex;
      flex-direction: column;
    }
  }

  &__link {
    @include text_link_menu;
    text-transform: uppercase;
    color: $black;
    opacity: 0.6;

    & + & {
      margin-top: 15px;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.header-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-left: 260px;
  a {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.6;
    }
    &:first-child img {
      margin-left: -2px;
    }
  }
}

.burger{
  display: none;
}

@media screen and (max-width: 992px) {
  .header {
    &__logo {
      width: 174px;
      margin-top: 0;
    }

    &._open {
      .burger {
        width: 50px;
        height: 50px;
        span {
          &:nth-child(3) {
            transform: translate(0, -40px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(1) {
            transform: translate(0, 6px) rotate(-45deg);
          }
        }
      }

      .menu {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }

  .menu {
    position: fixed;
    z-index: 10;
    inset: 0;
    max-width: 100vw;
    height: 100vh;

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 158px;
    padding-left: 20px;

    pointer-events: none;
    transition: all .3s;
    background-color: rgba(245, 245, 245, 0.5);
    backdrop-filter: blur(40px);
    opacity: 0;
  }

  .nav {
    max-width: 100%;
    &__list {
      flex-direction: column;
      gap: 30px;
      li {
        width: 100%;
      }
    }
    &__link {
      & + & {
        margin-top: 30px;
      }
    }
  }

  .header-buttons {
    flex-direction: row;
    margin: 60px 0 0;
    gap: 60px;
    a {
      width: 60px;
      height: 60px;
      &:nth-child(1) img {
        width: 37px;
        margin-left: -4px;
      }
      &:nth-child(2) img {
        width: 36px;
      }
    }
  }

  .burger {
    position: relative;
    z-index: 11;
    width: 83px;
    height: 22px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      position: absolute;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $black;
      transition: all .3s;
      &:nth-child(1) {
        width: 56px;
        top: 1px;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        width: 56px;
        bottom: 1px;
      }
    }
  }

}

@media screen and (max-width: 639px) {
  .menu {
    padding-top: 151px;
    padding-left: 10px;
  }

  .nav {
    &__list {
      gap: 20px;
    }
    &__link {
      & + & {
        margin-top: 20px;
      }
    }
  }

  .header {
    padding-top: 21px;
    padding-bottom: 23px;

    &-buttons {
      gap: 23px;

      a {
        width: 40px;
        height: 40px;

        &:nth-child(1) img {
          width: 25px;
        }

        &:nth-child(2) img {
          width: 24px;
        }
      }
    }
  }
  .burger {
    width: 59px;
    height: 16px;
    span {
      &:nth-child(2) {
        width: 100%;
      }
      &:nth-child(1), &:nth-child(3) {
        width: 39px;
      }
    }
  }
}
