.team__slider {
  &-thumbnails {
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 853px;
    top: 0;
    width: 1116px;
  }
  
  &-thumbnail {
    cursor: pointer;
    max-width: 264px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    opacity: 1;
    user-select: none;
    transition: all .8s;
    &-name {
      @include title_4;
      margin-bottom: 7px;
    }

    &-position {
      text-transform: uppercase;
      @include text_caption;
      opacity: 0.5;
    }

    &-img {
      max-width: 100%;
      margin-bottom: 20px;

      img {
        max-width: 265px;
        border-radius: 40px;
      }
    }
    &.swiper-slide-active {
      max-width: 0;
      margin-right: 0 !important;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .team__slider-thumbnails {
    visibility: hidden;
    display: none;
  }
}
