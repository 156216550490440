.contacts {
  max-width: 100vw;
  width: 100%;
  background-color: $black;
  border-radius: 40px 40px 0 0;

  &__marquee {
    max-height: 133px;
    height: 100%;
    margin-top: 53px;
    margin-bottom: 44px;
    display: flex;
    align-items: center;
    gap: 50px;
    overflow: hidden;


    span {
      @include title_3;
      text-transform: uppercase;
      color: rgba($white, .7);
    }


    &.node-marquee {
      max-height: 36px;
      .node-marquee__el {
        height: 100%;
        display: flex !important;
        align-items: center;
        gap: 50px;
        margin-right: 50px;

        img {
          width: 35px;
        }
      }
    }
  }

  &__inner {
    padding-top: 100px;
    position: relative;
    padding-bottom: 90px;
    margin-bottom: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: rgba($white, .7);
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
  &__title {
    margin-bottom: 70px;
    span {
      color: $white;
      @include headline__footer;
      text-transform: uppercase;
      display: block;

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 90px;
      }
    }
  }
  &__img {
    max-width: 264px;
    width: 100%;
    border-radius: 25px;
  }
  &__form {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1440px) {
  .contacts__inner {
    &::before,
    &::after {
      width: calc(100% - 40px);
    }
  }
}

@media screen and (max-width: 992px) {
  .contacts {
    &__marquee {
      margin: 25px 0;

        span {
          font-size: 18px;
        }


      &.node-marquee {
        .node-marquee__el {
          gap: 25px;
          margin-right: 25px;
          img {
            width: 25px;
          }
        }
      }
    }

    &__inner {
      padding-top: 70px;
      padding-bottom: 65px;
    }
    &__title {
      margin-bottom: 50px;
      span {
        &:last-child {
          gap: 20px;
        }
      }
    }
    &__img {
      width: 150px;
      border-radius: 20px;
    }
    &__form {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 639px) {
  .contacts {
    border-radius: 25px 25px 0 0;

    &__form {
      margin: 0 auto;
      max-width: 100%;
    }
    &__marquee {
      margin: 20px 0;

      span {
        font-size: 12px;
      }

      &.node-marquee {
        .node-marquee__el {
          gap: 15px;
          margin-right: 15px;
          img {
            width: 15px;
          }
        }
      }
    }

    &__inner {
      padding-top: 60px;
      padding-bottom: 50px;
      &::before,
      &::after {
        width: calc(100% - 20px);
      }
    }

    &__title {
      margin-bottom: 40px;
      span {
        font-size: 48px;
      }
    }
    &__img {
      width: 103px;
      border-radius: 10px;
    }
  }
}