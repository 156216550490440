.banner {

  &__inner {
    width: 100%;
    height: auto;
    background-color: $black;
    border-radius: 40px;
  }
  &__content {
    padding: 60px 0;
    margin: 0 auto;
    max-width: 832px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    span {
      color: $white;

      &:first-child {
        max-width: 398px;
        width: 100%;
        display: block;
        @include text_big;
      }
      &:last-child {
        @include text_main;
        opacity: .7;
      }
    }
  }
  &__btn {
    width: 100%;
    &-wrapper {
      max-width: 264px;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}

@media screen and (max-width: 992px) {
  .banner {
    &__inner {
      border-radius: 25px;
    }
    &__content {
      padding: 40px 82px 40px 33px;
      flex-direction: column;
      gap: 35px;
    }
    &__text {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      span {

        &:first-child {
          max-width: 268px;
        }
        &:last-child {
          max-width: 105px;
        }
      }
    }
    &__btn-wrapper {
      max-width: 393px;
    }
  }
}

@media screen and (max-width: 639px) {
  .banner {
    &__inner {
      border-radius: 20px;
    }
    &__content {
      padding: 30px 12px;
      flex-direction: column;
      gap: 30px;
    }
    &__text {
      flex-direction: column;
      gap: 10px;
      span {
        &:first-child {
          max-width: 154px;
        }
        &:last-child {
          font-weight: 400;
          font-size: 11px;
          max-width: 100%;
        }
      }
    }
    &__btn-wrapper {
      max-width: 100%;
    }
  }
}