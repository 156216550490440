.cover {
  margin-top: 110px;
  position: relative;
  &__title {
    display: flex;
    flex-flow: row wrap;
    column-gap: 30px;
    align-items: center;
    font-weight: 700;
    font-size: 160px;
    line-height: 115%;
    text-transform: uppercase;
    color: $black;
    span {
      display: inline-block;
      &:nth-child(1) {
        margin-left: 285px;
        flex: 1 0 100%;
      }
      &:nth-child(4) {
        margin-left: 207px;
      }

      &.stroke {
        color: $grey;
        -webkit-text-stroke: 2px $black;
      }
    }
  }
  &__subtitle {
    max-width: 832px;
    margin: 70px 0 0 auto;
  }
  &__cta {
    position: absolute;
    top: -44px;
    right: 215px;
    animation: spin 8s infinite ease-in-out;
    &._active {
      transition: opacity .4s;
      opacity: 1;
    }
    &:hover {
      animation-play-state: paused;
    }
  }
}

@media screen and (max-width: 1400px) {
  .cover {
    &__title {
      column-gap: 25px;
      font-size: 140px;
      span {
        &:nth-child(4) {
          margin-left: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .cover {
    &__title {
      column-gap: 20px;
      font-size: 125px;
      span {
        &:nth-child(4) {
          margin-left: 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .cover {
    margin-top: 60px;
    &__title {
      font-size: 115px;
      span {
        display: block;
        width: 100%;
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(4) {
          margin-left: 0;
        }
      }
    }
    &__subtitle {
      max-width: 365px;
      margin: 60px 28px 0 auto;
    }
    &__cta {
      animation-name: spin-tablet;
      top: 307px;
      right: 0;
    }
  }
}

@media screen and (max-width: 639px) {
  .cover {
    &__title {
      font-size: 65px;
    }
    &__subtitle {
      max-width: 207px;
      margin: 34px 15px 0 auto;
    }
    &__cta {
      animation-name: spin-mob;
      animation-duration: 6s;
      top: 169px;
      right: 0;
    }
  }
}