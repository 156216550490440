@import "../mixins";

.form {
  max-width: 416px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    & + & {
      margin-top: 10px;
    }

  }

  &__input {
    width: 100%;
    height: 50px;
    padding-left: 16px;
    padding-right: 16px;

    opacity: .6;
    border-radius: 15px;
    background-color: transparent;

    @include text_form;

    &-light {
      border: 1px solid $black;
      color: $black;
    }

    &-dark {
      border: 1px solid $white;
      color: $white;
      &::placeholder {
        color: $white;
      }
    }

    &:hover {
      opacity: .8;
    }
    &:focus {
      opacity: 1;
    }
    &._error {
      border: 1px solid $red-border;
      opacity: 0.7;
    }

  }
  .just-validate-error-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
  }
}

