*,
 ::after,
::before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none !important;
}

body,
div,
p,
h1,
h2,
h3,
h4,
h5,
ul,
li,
a,
form {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    line-height: 1;
}

button,
input,
textarea {
    appearance: none;
    outline: none !important;
    -webkit-transition: all .125s;
    -o-transition: all .125s;
    transition: all .125s;
    font-family: inherit;
}

input[type=text]:focus,
input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus,
input:active {
    outline: none !important;
}

::-webkit-input-placeholder {
    color: #222;
}

::-moz-placeholder {
    color: #222;
}


/* Firefox 19+ */

:-moz-placeholder {
    color: #222;
}


/* Firefox 18- */

:-ms-input-placeholder {
    color: #222;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

svg,
path {
    -webkit-transition: all .125s;
    -o-transition: all .125s;
    transition: all .125s;
}

a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: 0.125s;
    -o-transition: 0.125s;
    transition: 0.125s;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
    }
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

i {
    line-height: 100%;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
    box-sizing: border-box;
    transition: 0.125s;
    background-color: inherit;
    font-size: 1em;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 2;
    &::after {
        display: none;
    }
}
