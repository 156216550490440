.team {
  position: relative;
  &__title {
    position: absolute;
    top: 0;
    left: 20px;
  }

  &__subtitle {
    margin-left: auto;
    margin-bottom: 70px;
    max-width: 1116px;
  }
  &__caption {
    max-width: 536px;
    @include text_main;
    margin: 0 294px 70px auto;
    opacity: .9;
  }
  &__sliders {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__slider {
    max-width: 833px;
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-wrapper {
      position: relative;
      height: 610px;
      max-width: 833px;
      width: 100%;
    }
  }

  &-slide {
    position: absolute;
    width: 100%;
    display: flex;
    gap: 20px;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
    transition: opacity .4s, visibility .2s;

    &__name {
      @include title_4;
      margin-bottom: 7px;
    }

    &__img {
      max-width: 548px;
      margin-bottom: 20px;

      img {
        width: 100%;
        border-radius: 40px;
      }
    }

    &__position {
      text-transform: uppercase;
      @include text_caption;
      opacity: 0.5;
    }

    &__quote {
      max-width: 264px;
      opacity: 0.7;
      @include text_small;
    }

    &.swiper-slide-active {
      background-color: $grey;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 992px) {
  .team {
    .swiper-pagination {
      width: 0;
      display: none;
      visibility: hidden;
    }
    &__subtitle {
      max-width: 491px;
      margin-left: auto;
      margin-bottom: 25px;
      span {
        display: block;
      }
    }
    &__slider {
      overflow: visible;
      .swiper-wrapper {
        max-width: 100%;
        height: auto;
      }
    }
    &__caption {
      margin-right: 0;
      margin-bottom: 35px;
      max-width: 389px;
    }
    &-slide {
      visibility: visible;
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 15px;
      max-width: 393px;
      height: 475px;
      background-color: $grey;
      opacity: 1;

      &__img {
        max-width: 100%;
        margin-bottom: 15px;

        img {
          border-radius: 25px;
        }
      }
      &__name {
        max-width: 185px;
        margin-left: 15px;
      }
      &__position {
        max-width: 185px;
        margin-left: 15px;
      }
      .team-slide__quote {
        visibility: visible;
        opacity: .7;
      }

      &__quote {
        position: absolute;
        top: 408px;
        right: 40px;
        max-width: 147px;

      }
    }
  }
}

@media screen and (max-width: 639px) {
  .team {
    &__title {
      left: 10px;
    }
    &__subtitle {
      max-width: 280px;
      margin-left: auto;
      margin-bottom: 20px;

    }
    &__caption {
      margin: 0 auto 30px;
    }

    &-slide {
      gap: 10px;
      max-width: 325px;
      height: 415px;

      &__img {
        margin-bottom: 10px;
        img {
          border-radius: 20px;
        }
      }
      &__name {
        margin-left: 12px;
      }
      &__position {
        margin-left: 12px;
      }
      &__quote {
        max-width: 77%;
        position: static;
        margin-left: 12px;
      }
    }
  }
}