.feedback {
  position: relative;
  overflow: hidden;
  &__title {
    position: absolute;
    left: 20px;
  }
  &__inner {
    position: relative;
    max-width: 1176px;
    height: 555px;
    margin-bottom: 45px;
    margin-inline: auto;

    p {
      &:nth-child(1) {
        bottom: 0;
        right: 258px;
      }
      &:nth-child(2) {
        bottom: 37px;
        right: 714px;
        transform: rotate(-12.52deg);
      }
      &:nth-child(3) {
        bottom: 94px;
        right: -27px;
        transform: rotate(33.29deg);
      }
      &:nth-child(4) {
        bottom: 188px;
        right: 436px;
        transform: rotate(15.8deg);
      }
    }
  }
  &__cta {
    position: absolute;
    top: 0;
    right: 350px;
    opacity: 1;
  }
  &__name {
    position: absolute;
    width: 482px;
    border: 1px solid $black;
    border-radius: 90px;
    text-align: center;
    overflow: hidden;
    background-color: $grey;
    a {
      display: block;
      padding-top: 44px;
      padding-bottom: 43px;
      @include tile;
    }
  }
  &__list {
    max-width: 1118px;
    margin-inline: auto;
    display: flex;
    gap: 22px;
  }
  &__item {
    padding: 33px 34px 37px;
    border: 1px solid $black;
    border-radius: 40px;

    &-img {
      max-width: 105px;
      margin-bottom: 15px;

      img {
        display: block;
        width: 100%;
        border-radius: 25px;
      }
    }
    &-name {
      margin-bottom: 7px;
      @include title_4;
      text-transform: capitalize;
    }
    &-position {
      @include text_caption;
      opacity: .5;
    }
  }
  &__info {
    margin-bottom: 40px;
  }
  &__review {
    max-width: 458px;
    @include text_main;
    opacity: .9;
  }
}

@media screen and (max-width: 992px){
  .feedback {
    &__inner {
      height: 464px;
      margin-top: 55px;
      p {
        &:nth-child(1) {
          right: 106px;

        }
        &:nth-child(2) {
          bottom: 51px;
          right: 315px;
          transform: rotate(-31.27deg);
        }
        &:nth-child(3) {
          right: -67px;
          bottom: 96px;
          transform: rotate(71.45deg);
        }
        &:nth-child(4) {
          bottom: 154px;
          right: 124px;
          transform: rotate(32.47deg);
        }
      }
    }
    &__cta {
      top: 10px;
      right: 117px;
    }
    &__list {
      max-width: 100%;
      margin: 0 auto;
      gap: 20px;
    }
    &__item {
      padding: 21px;
      border-radius: 30px;

      &-img {
        margin-bottom: 10px;
        img {
          border-radius: 20px;
        }
      }
      &-name {
        margin-bottom: 5px;
      }

    }
    &__name {
      width: 300px;
      a {
        padding: 30px 0 29px;
      }
    }
    &__info {
      margin-bottom: 25px;
    }
    &__review {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 639px){
  .feedback {
    &__list {
      flex-direction: column;
    }
    &__item {
      width: 100%;
      height: 171px;
      padding: 15px 20px 15px 15px;
      position: relative;
      border-radius: 25px;

      &-img {
        max-width: 88px;
        margin-bottom: 0;
      }
    }
    &__info {
      display: flex;
      gap: 15px;
    }
    &__review {
      max-width: 60%;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
    &__inner {
      margin-top: 47px;
      margin-bottom: 41px;
      height: 275px;

      p {
        &:nth-child(1) {
          right: 61px;
        }
        &:nth-child(2) {
          bottom: 32px;
          right: 175px;
          transform: rotate(-33.53deg);
        }
        &:nth-child(3) {
          right: -39px;
          bottom: 55px;
        }
        &:nth-child(4) {
          right: 68px;
          bottom: 92px;
          transform: rotate(36.48deg);
        }
      }
    }
    &__cta {
      top: 1px;
      right: 61px;
    }
    &__name {
      width: 174px;
      a {
        padding: 17px 0;
      }
    }
  }
}