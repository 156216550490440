@import "../vars";


.button {
  text-transform: uppercase;
  cursor: pointer;

  @include text_button;
  padding: 14px;
  border-radius: 24.5px;

  &__light {
    background: $black;
    color: $white;
    border: 1px solid $black;
    &:hover {
      background: $white;
      color: $black;
    }
  }

  &__dark {
    background: $white;
    color: $black;
    border: 1px solid $white;
    &:hover{
      background: $black;
      color: $white;
    }
  }

  &__circle {
    @include text_button_circle;
    width: 272px;
    height: 272px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;

    span {
      max-width: 133px;
    }

    &-light {
      border: 2px solid $black;
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(10px);
      color: $black;
      &:hover {
        background: #F5F5F5;
      }
    }
    &-dark {
      background-color: $black;
      color: $white;
      &:hover {
        border: 2px solid $black;
        background: $grey;
        color: $black;
      }
    }

  }
  &._disabled {
    cursor: default;
    &:hover {
      .button__dark {
        background-color: $black;
        color: $white;
      }
      .button__light {
        color: $black;
        background-color: $grey;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .button {
    &__circle {
      width: 207px;
      height: 207px;
    }
  }
}

@media screen and (max-width: 639px) {
  .button {
    &__circle {
      width: 126px;
      height: 126px;
    }
  }
}