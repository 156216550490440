.blog {
  position: relative;
  &__nav {
    position: absolute;
    top: 0;
    right: 20px;
    width: 130px;
    height: 55px;
    display: flex;
    gap: 20px;

    &-arrow {
      top: 0;
      width: 55px;
      height: 100%;
      margin-top: 0;
      border: 1px solid $black;
      border-radius: 50%;
      opacity: .3;

      &:hover {
        opacity: 1;
      }
    }

    &-next {
      right: 0;
    }

    &-prev {
      left: 0;
    }
  }

  &__slider {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  &-slide {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $black;
    text-transform: uppercase;
    margin-top: 60px;
    &__img {
      max-width: 548px;
      width: 100%;
      max-height: 548px;
      height: 100%;
      margin-right: 20px;
      img {
        width: 100%;
        display: block;
        border-radius: 40px;
      }
    }
    &__info {
      position: relative;
      margin-top: 5px;
      max-width: 531px;
      width: 100%;
    }
    &__title {
      max-width: 440px;
      @include title_3;
    }
    &__date {
      margin: 20px 0 40px;
      @include text_caption;
      opacity: 0.5;
    }
    &__preview {
      margin-bottom: 10px;
      @include text_main;
      opacity: 0.9;
      text-transform: none;
    }
    &__link {
      margin-bottom: 3px;
    }
    &__vc {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      &-mob {
        visibility: hidden;
      }
    }
  }
}


@media screen and (max-width: 992px) {
  .blog {
    height: 785px;
    overflow: hidden;
    &__nav {
      position: static;
      visibility: hidden;
      display: none;
    }
    &__slider {
      overflow: visible;
    }
    &-slide {
      margin-top: 25px;
      flex-direction: column;
      &__img {
        max-width: 393px;
        img {
          border-radius: 25px;
        }
      }

      &__title {
        max-width: 93%;
        flex: 1;
      }

      &__info {
        margin-top: 15px;
        max-width: 393px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 276px;
      }
      &__preview {
        margin-bottom: 7px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      &__date {
        margin: 7px 0 25px;
      }
      &__vc {
        &-desk {
          display: none;
        }
        &-mob {
          left: auto;
          right: 20px;
          visibility: visible;
        }
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .blog {
    height: 700px;

    &-slide {
      &__info {
        height: 253px;
      }
      &__preview {
        -webkit-line-clamp: 8;
      }
      &__img {
        img {
          border-radius: 20px;
        }
      }
      .blog-slide__link {
        text-underline-offset: auto;
      }
      &__vc {
        &-mob {
          right: 25px;
        }
      }
    }
  }
}